<template>
  <div>
    <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 xl:gap-x-8 gap-4">
      <Card>
        <template #title>
          Users
        </template>

        <template #content>
          <div class="text-sm opacity-50 truncate">
            1 User Active in The Last Week
          </div>
        </template>
      </Card>

      <Card>
        <template #title>
          Websites
        </template>

        <template #content>
          <div class="text-sm opacity-50 truncate">
            3 Secure, 7 Vulnerable, 4 Critical 
          </div>
        </template>
      </Card>

      <Card>
        <template #title>
          Completed Scans
        </template>

        <template #content>
          <div class="text-sm opacity-50 truncate">
            Completed in 23:56 293 Full Scars 
          </div>
        </template>
      </Card>

      <Card>
        <template #title>
          Users
        </template>

        <template #content>
          <div class="text-sm opacity-50 truncate">
            1 User Active in The Last Week
          </div>
        </template>
      </Card>

      <Card class="col-span-1 md:col-span-2">
        <template #title>
          Vulnerability Stats 
        </template>

        <template #content>
          <div class="flex justify-center items-center">
            <DoughnutChart />
          </div>
        </template>
      </Card>

      <Card class="col-span-1 md:col-span-2">
        <template #title>
          Issue Types
        </template>

        <template #content>
          <div class="flex justify-center items-center">
            <DoughnutChart />
          </div>
        </template>
      </Card>

      <div class="flex flex-col">
        <Card
          bg-color="dark-blue"
          class="mb-4 h-full"
        >
          <template #content>
            <div class="flex flex-col items-center py-2">
              <StarIcon
                class="w-7 h-7"
              />

              <div class="text-3xl pt-1">
                LOW
              </div>

              <div class="text-base font-semibold pt-3">
                Threat Level
              </div>
            </div>

            <div class="text-xs text-center pb-2"> 
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sit est lectus diam cras pellentesque donec id dictum. Viverra in gravida leo, velit cras aliquam sed eros. Nunc augue magna at enim, et a quam. Faucibus semper nisl fermentum, at.
            </div>
          </template>
        </Card>

        <Card>
          <template #title>
            Checks Run
          </template>

          <template #content>
            <div class="w-full h-full">
              <div class="flex flex-row justify-between w-full">
                <div>
                  <div class="text-2xl">
                    319
                  </div>

                  <div class="text-sm opacity-50">
                    Number of Errors
                  </div>
                </div>

                <div class="flex flex-row items-center gap-0.5 text-green-500">
                  <div class="">
                    <ArrowUpIcon
                      class="w-4 h-4 transform rotate-45"
                    />
                  </div>

                  <div>
                    1.9%
                  </div>
                </div>
              </div>
            </div>
          </template>
        </Card>
      </div>

      <Card>
        <template #title>
          Issues
        </template>

        <template #content>
          <div class="grid grid-cols-2 gap-1 md:gap-2">
            <div
              v-for="item in issueItems"
              :key="item.title"
              class="text-gray-900 font-normal rounded p-2 py-3 flex items-center justify-between"
              :class="getIssueBgColor(item.priority)"
            >
              <div class="truncate text-xs">
                {{ item.title }}
              </div>

              <div 
                v-if="item.value > 0"
                class="font-semibold text-xs"
              >
                {{ item.value }}
              </div>
            </div>
          </div>

          <div class="mt-4">
            <!-- TODO: 5 tane listelenecek -->
            <div
              v-for="(item, index) in issueDetailsItems"
              :key="item.title"
              class="flex flex-row items-center gap-2"
              :class="index > 0 ? 'pt-2' : ''"
            >
              <div>
                <ExclamationCircleIcon
                  class="w-4 h-4"
                  :class="getIssueIconColor(item.priority)"
                />
              </div>

              <div class="text-xs text-gray-400">
                No ıssues and older than their remediotion target
              </div>
            </div>
          </div>
        </template>
      </Card>

      <div class="col-span-1 md:col-span-2">
        <div class="flex flex-col md:flex-row gap-4">
          <Card class="w-full">
            <template #title>
              Cyber Hygiene Score
            </template>

            <template #content>
              <div class="flex flex-row items-center">
                <span class="text-7xl text-blue-600">A</span>

                <PlusIcon
                  class="w-12 h-12 text-blue-600"
                />
              </div>

              <div class="text-3xl text-blue-600 font-light">
                Excellent
              </div>

              <div class="opacity-50 text-xs">
                No ıssues and older than their remediotion target
              </div>
            </template>
          </Card>

          <Card class="w-full">
            <template #title>
              Avarage Time to Fix Issues
            </template>

            <template #content>
              <div class="flex flex-row items-center">
                <span class="text-7xl text-red-500">11</span>

                <ClockIcon
                  class="w-8 h-8 text-red-500"
                />
              </div>

              <div class="text-3xl text-red-500 font-light">
                Correct
              </div>

              <div class="text-xs opacity-50">
                Calculated enough time to debug all bugs.
              </div>
            </template>
          </Card>
        </div>

        <Card class="flex col-span-4 mt-4">
          <template #title>
            Avarage Time To Fix
          </template>

          <template #content>
            <BarChart />
          </template>
        </Card>
      </div>

      <div class="col-span-1 md:col-span-2 lg:col-span-4">
        <Card>
          <template #title>
            Exposure Over Time
          </template>

          <template #content>
            <LineChart />
          </template>
        </Card>
      </div>

      <OwaspTable class="col-span-2 md:col-span-2 lg:col-span-4" />

      <Card class="col-span-1 md:col-span-2 lg:col-span-2">
        <template #content>
          <ActivityFeed />
        </template>
      </Card>
    
      <Card class="col-span-1 md:col-span-2 lg:col-span-2">
        <template #content>
          <ActivityFeed />
        </template>
      </Card>
    </div>
  </div>
</template>

<script>
import Card from '@/components/Card.vue';

import { StarIcon, ArrowUpIcon } from '@heroicons/vue/outline'
import { ExclamationCircleIcon, PlusIcon, ClockIcon } from '@heroicons/vue/solid'
import DoughnutChart from '@/components/DoughnutChart.vue'
import BarChart from '@/components/BarChart.vue'
import LineChart from '@/components/LineChart.vue'
import OwaspTable from '@/components/OwaspTable.vue'
import ActivityFeed from '@/components/ActivityFeed.vue'

export default {
  components: {
    Card,
    StarIcon,
    ArrowUpIcon,
    ExclamationCircleIcon,
    PlusIcon,
    ClockIcon,
    DoughnutChart,
    BarChart,
    LineChart,
    OwaspTable,
    ActivityFeed
  },

  data: () => ({
    issueItems: [
      {
        title: 'Critical',
        value: 0,
        priority: 'critical',
      },
      {
        title: 'High',
        value: 0,
        priority: 'high',
      },
      {
        title: 'Medium',
        value: 1,
        priority: 'medium',
      },
      {
        title: 'Low',
        value: 2,
        priority: 'low',
      },
    ],
    issueDetailsItems: [
      {
        text: 'No ıssues and older than their remediotion target',
        priority: 'medium',
      },
      {
        text: 'No ıssues and older than their remediotion target',
        priority: 'low',
      },
      {
        text: 'No ıssues and older than their remediotion target',
        priority: 'low',
      },
      {
        text: 'No ıssues and older than their remediotion target',
        priority: 'low',
      },
      {
        text: 'No ıssues and older than their remediotion target',
        priority: 'low',
      },
    ],
  }),

  methods: {
    getIssueBgColor(priority) {
      switch(priority) {
        case 'critical':
          return 'bg-critical-issue';
        case 'high':
          return 'bg-high-issue';
        case 'medium':
          return 'bg-medium-issue';
        case 'low':
          return 'bg-low-issue';
        default:
          return 'text-black bg-white';
      }
    },

    getIssueIconColor(priority) {
      switch(priority) {
        case 'critical':
          return 'text-critical-issue';
        case 'high':
          return 'text-high-issue';
        case 'medium':
          return 'text-medium-issue';
        case 'low':
          return 'text-low-issue';
        default:
          return 'text-white';
      }
    },
  },
}
</script>

<style lang="css" scoped>
  .p-0 {
    padding: 0 !important;
  }
</style>
