<template>
  <tr class="border-b border-gray-300 text-xs">
    <td class="pl-4 py-4">
      <div class="font-bold">
        A{{ index }}
      </div>
    </td>

    <td class="pl-3">
      <div class="font-weight-thin line-clamp-2">
        {{ solution }}
      </div>
    </td>

    <td class="pl-2">
      <div class="font-weight-thin line-clamp-2">
        {{ suggestion }}
      </div>
    </td>

    <td class="pl-2 text-gray-700">
      {{ date }}
    </td>

    <td
      class="pl-2"
      :class="priorityTextColor"
    >
      {{ priority }}
    </td>

    <td class="pr-4 pl-2">
      <div
        class="text-2xs text-white rounded-full flex justify-center items-center py-1 px-2 uppercase"
        :class="statusColor"
      >
        {{ status }}
      </div>
    </td>
  </tr>
</template>

<script>
export default {
  props: {
    date: {
      type: String,
      default: '01/01/1970'
    },

    priority: {
      type: String,
      default: 'Low'
    },

    solution: {
      type: String,
      required: true
    },

    suggestion: {
      type: String,
      required: true
    },

    index: {
      type: String,
      default: '0'
    },

    status: {
      type: String,
      default: 'on hold'
    }
  },

  computed: {
    statusColor() {
      if (this.status === 'on hold') {
        return 'bg-gray-300';
      } else if (this.status === 'open') {
        return 'bg-yellow-300';
      } else if (this.status === 'completed') {
        return 'bg-green-300';
      }else {
        return 'bg-red-500';
      }
    },

    priorityTextColor() {
      if(this.priority === 'High') {
        return 'text-red-500';
      } else {
        return 'text-gray-700';
      }
    }
  },
};
</script>