<template>
  <apexchart
    type="line"
    :options="options"
    :series="series"
    height="300"
  />
</template>

<script>
export default {
  components: {
  },

  data: () => ({
    options: {
      colors: ['rgb(255, 157, 157)', 'rgb(175, 193, 255)', 'rgb(255, 194, 138)', 'rgb(179, 244, 225)'],
      chart: {
        type: 'line',
        zoom: {
          enabled: true
        },
        height: '100px',
      },
      legend: {
        position: 'bottom',
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: 'straight'
      },
      grid: {
        row: {
          colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
          opacity: 0.3
        },
      },
      xaxis: {
        categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep'],
      }
    },
    series: [{
        name: 'Critical',
        data: [1, 2, 3, 4, 5, 6, 6, 7, 9]
    },
    {
        name: 'High',
        data: [1, 3, 2, 5, 7, 6, 4, 2, 0]
    },
    {
        name: 'Medium',
        data: [3, 9, 2, 1, 2, 7, 4, 7, 2]
    },
    {
        name: 'Low',
        data: [3, 6, 5, 8, 9, 3, 6, 9, 0]
    },
    ],
  }),
}
</script>
