<template>
  <apexchart
    width="370"
    type="donut"
    :options="options"
    :series="series"
  />
</template>

<script>
export default {
  components: {
  },

  data: () => ({
    options: {
      labels: ['Apparel', 'Electronics', 'FMCG', 'Other Sales'],
      colors: ['rgb(255, 157, 157)', 'rgb(175, 193, 255)', 'rgb(255, 194, 138)', 'rgb(179, 244, 225)'],
      chart: {
        type: 'donut',
        height: 100,
        sparkline: {
          enabled: false,
        }
      },
      legend: {
        position: 'right',
      },
      responsive: [
        {
          breakpoint: 1000,
          options: {
            plotOptions: {
            },
            legend: {
              position: 'top',
            }
          }
        },
        {
          breakpoint: 720,
          options: {
            plotOptions: {
              pie: {
                donut: {
                  size: '70%',
                  labels: {
                    show: true,
                    name: {
                      fontSize: '1px',
                      offsetY: 25,
                    },
                    value: {
                      fontSize: '18px',
                      fontWeight: 600,
                    },
                    total: {
                      fontSize: '12px',
                    },
                  },
                },
              },
            },
            legend: {
              position: 'top',
            },
            chart: {
              width: '300px',
            },
          }
        }
      ],
      plotOptions: {
        pie: {
          dataLabels: {
            show: false,
            offset: 9999999999,
            minAngleToShowLabel: 10
          },
          donut: {
            size: '70%',
            labels: {
              show: true,
              name: {
                fontSize: '14px',
                offsetY: 25,
              },
              value: {
                fontSize: '24px',
                fontWeight: 600,
                offsetY: -15,
                formatter(value) {
                  return `${value}K`
                },
              },
              total: {
                show: true,
                label: 'Engagement',
                color: 'rgba(94, 86, 105, 0.68)',
                fontSize: '16px',
                formatter(value) {
                  return `${value.globals.seriesTotals.reduce((total, num) => total + num)}M`
                },
              },
            },
          },
        },
      },
    },
    series: [44, 55, 41, 17],
  }),
}
</script>
