<template>
  <apexchart
    :options="options"
    :series="series"
    height="140"
    class="min-h-0"
  />
</template>

<script>

export default {
  data: () => ({
    counter: 0,
    series: [{
      data: [{
        x: 'A',
        y: 13,
      }, {
        x: 'B',
        y: 9
      }, {
        x: 'C',
        y: 34,
      }]
    }],
  }),

  computed: {
    options() {
      return {
        colors: [
          this.colorizer,
        ],
        chart: {
          type: 'bar',
          width: '200px'
        },
      };
    }
  },

  methods: {
    colorizer() {
      this.counter += 1;

      if (this.counter === 1) {
        return 'rgb(255, 157, 157)'
      } else if (this.counter === 2) {
        return 'rgb(175, 193, 255)'
      } else {
        this.counter = 0;
        return 'rgb(255, 194, 130)';
      }
    },
  },
}
</script>

<style scoped>
  .min-h-0 {
    min-height: 0 !important;
  }
</style>
