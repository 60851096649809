<template>
  <div 
    class="rounded-lg bg-white shadow-lg pt-4 flex flex-col gap-1"
  >
    <div
      class="text-md font-semibold pb-2 px-4"
    >
      OWASP TOP 10
    </div>

    <table class="divide-y divide-gray-300">
      <tbody>
        <OwaspTableItem
          solution="Broken Access Control moves up from the fifth position; 94% of applications were tested for some form of broken access control."
          suggestion="Revamp Design System based on new vision"
          date="3/01/2022"
          priority="Low"
          index="01"
          status="completed"
        />

        <OwaspTableItem
          solution="Cryptographic Failures shifts up one position to #2, previously known as Sensitive Data Exposure, which was broad symptom rather than a root cause."
          suggestion="Revamp Design System based on new vision"
          date="1/01/2022"
          priority="High"
          index="02"
          status="overdue"
        />

        <OwaspTableItem
          solution="Anjection slides down to the third position. 94% of the applications were tested for some form of injection, and the 33 CWEs"
          suggestion="Revamp Design System based on new vision"
          date="12/12/2021"
          priority="High"
          index="03"
          status="open"
        />

        <OwaspTableItem
          solution="Insecure Design is a new category for 2021, with a focus on risks related to design flaws. If we genuinely want to “move left” as an industry"
          suggestion="Revamp Design System based on new vision"
          date="28/11/2021"
          priority="Low"
          index="04"
          status="open"
        />

        <OwaspTableItem
          solution="Security Misconfiguration moves up from #6 in the previous edition; 90% of applications were tested for some form of misconfiguration."
          suggestion="Revamp Design System based on new vision"
          date="23/11/2021"
          priority="Low"
          index="04"
          status="on hold"
        />
      </tbody>
    </table>
  </div>
</template>

<script>
import OwaspTableItem from '@/components/OwaspTableItem.vue';

export default {
  components: {
    OwaspTableItem,
  },
};
</script>