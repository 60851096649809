<template>
  <!--
    Next Scheduled Scans
  -->

  <div v-if="scheduledScans">
    <div class="text-sm font-medium pb-3">
      Next Scheduled Scans
    </div>

    <div
      v-for="(item, index) in scheduledScans"
      :key="index+9939"
      class="text-xs flex justify-between pl-1 pr-2 pb-3 items-center"
    >
      <div
        class="w-full truncate"
        v-text="item.name"
      />

      <div
        class="text-gray-600 w-full"
        v-text="item.duration"
      />

      <div class="rounded-full border-gray-500 border p-1 cursor-pointer">
        <PencilIcon class="w-3 h-3 text-black" />
      </div>
    </div>
  </div>

  <!-- 
    Recent scan
  -->

  <div v-if="recentScans">
    <div class="text-sm font-medium pb-3">
      Recent Scans
    </div>

    <div
      v-for="(item, index) in recentScans"
      :key="index+9389"
      class="text-xs flex justify-between pl-1 pr-2 pb-3 items-center"
    >
      <div class="pr-2">
        <InformationCircleIcon class="w-5 h-5 text-black" />
      </div>

      <div
        class="w-full truncate"
        v-text="item.name"
      />

      <div class="flex pr-8">
        <div
          class="bg-red-700 text-white w-6 text-center rounded-l-md"
          v-text="item.criticalThreat"
        />

        <div 
          class="bg-yellow-600 text-white w-6 text-center"
          v-text="item.highThreat"
        />

        <div
          class="bg-yellow-400 text-white w-6 text-center"
          v-text="item.mediumThreat" 
        />

        <div
          class="bg-yellow-200 text-white w-6 text-center rounded-r-md"
          v-text="item.lowThreat"
        />
      </div>

      <div class="rounded-full border-gray-500 border p-1 cursor-pointer">
        <SearchIcon class="w-3 h-3 text-black" />
      </div>
    </div>
  </div>


  <!-- 
    Websites That Have Shortest Fix Time
    v-if expected
  -->

  <div v-if="websitesFixTime">
    <div class="text-sm font-medium pb-3">
      Websites That Have Shortest Fix Time
    </div>

    <div
      v-for="(item, index) in websitesFixTime"
      :key="index+9939"
      class="text-xs flex justify-between pl-1 pr-2 pb-3 items-center"
    >
      <div
        class="w-full truncate"
        v-text="item.name"
      />

      <div
        class="text-gray-500 w-full"
        v-text="item.duration"
      />

      <div class="rounded-full border-gray-500 border p-1 cursor-pointer">
        <PencilIcon class="w-3 h-3 text-black" />
      </div>
    </div>
  </div>
</template>

<script>
import { PencilIcon, InformationCircleIcon, SearchIcon } from '@heroicons/vue/outline'

export default {
  components: {
    PencilIcon,
    InformationCircleIcon,
    SearchIcon,
  },

  data: () => ({
    scheduledScans: [
      {
        name: 'PHP Testsparker - Weekly',
        duration: 'Starting in 1d 18h 37m',
      },
      {
        name: 'ASPNET Testsparker - Monthly',
        duration: 'Starting in 4w 2d 17h',
      }
    ],
    
    recentScans: [
      {
        name: 'ASPNET Testsparker',
        criticalThreat: 8,
        highThreat: 22,
        mediumThreat: 11,
        lowThreat: 6,
      },
      {
        name: 'PHP Testsparker',
        criticalThreat: 4,
        highThreat: 23,
        mediumThreat: 1,
        lowThreat: 3,
      },
      {
        name: 'Java Testsparker',
        criticalThreat: 9,
        highThreat: 2,
        mediumThreat: 1,
        lowThreat: 0,
      },
      {
        name: 'HTML Testsparker',
        criticalThreat: 8,
        highThreat: 22,
        mediumThreat: 11,
        lowThreat: 6,
      },
      {
        name: 'Python Testsparker',
        criticalThreat: 0,
        highThreat: 0,
        mediumThreat: 2,
        lowThreat: 1,
      },
      {
        name: 'ASPNET Testsparker',
        criticalThreat: 3,
        highThreat: 3,
        mediumThreat: 2,
        lowThreat: 0,
      },
      {
        name: 'ASPNET Testsparker',
        criticalThreat: 10,
        highThreat: 23,
        mediumThreat: 21,
        lowThreat: 93,
      },
      {
        name: 'ASPNET Testsparker',
        criticalThreat: 8,
        highThreat: 22,
        mediumThreat: 11,
        lowThreat: 6,
      },
    ],

    websitesFixTime: [
      {
        name: 'Super Blue',
        duration: '0d',
      },
      {
        name: 'New Juice',
        duration: '0d',
      },
      {
        name: 'New Juice',
        duration: '0d',
      },
      {
        name: 'Blue Fizz - UK',
        duration: '0d',
      },
    ],
  }),
}
</script>